<template>
	<TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="name"
        initSortOrder="asc"
        :filters="filters"
        addButtonName="Add ISIN"
        :import-config="{}"
        :export-config="{}"
        pagination>

        <template v-slot:import_tpl>
            <div style="word-break: break-word;">
                Excel file for <b>import</b> should have the same structure as Excel file with exported data. The best practice is to download Excel file, change necessary data and upload changed file.<br><br>
                <i>Please, note that value 1 in column <b>Reuters ON</b> means that data for this ISIN will be taken from Reuters, and 0 means that it will not</i><br><br>
                Example:<br>
                <table border="1" cellspacing="0" cellpadding="2" width="100%">
                    <tr>
                        <td nowrap style="font-size:10px;">ISIN</td>
                        <td nowrap style="font-size:10px;">RIC</td>
                        <td nowrap style="font-size:10px;">Reuters ON</td>
                    </tr>
                    <tr>
                        <td nowrap style="font-size:10px;">US94106LBD01</td>
                        <td nowrap style="font-size:10px;"></td>
                        <td nowrap style="font-size:10px;">1</td>
                    </tr>
                    <tr>
                        <td nowrap style="font-size:10px;">US29250N4777</td>
                        <td nowrap style="font-size:10px;">ENB.TO</td>
                        <td nowrap style="font-size:10px;">1</td>
                    </tr>
                </table>
            </div>
        </template>

        <template v-slot:form_items="{formdata}">
    		<el-form-item label="ISIN" prop="isin">
                <el-input v-model="formdata.isin" maxlength="12" minlength="12" />
            </el-form-item>
    		<el-form-item label="Equity ISIN" prop="equity_isin">
                <el-input v-model="formdata.equity_isin" />
            </el-form-item>
    		<el-form-item label="Reuters ON" prop="actual">
                <el-checkbox v-model="formdata.actual"></el-checkbox>
            </el-form-item>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'source_bond_isin',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
        	module: 'sourcesBondIsin',
            form_initial: {
            	actual: true
            },
            formRules: {
            	isin: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 12, message: 'this field should have 12 characters', trigger: 'blur' },
                    { max: 12, message: 'this field should have 12 characters', trigger: 'blur' },
                ]
            },
            tableColumns: [
                {
                    field  : 'isin', 
                    title  : 'ISIN', 
                    width  : 200, 
                    fixed  : 'left',
                    params : {
                        filter : 'input',
                    },
                }, {
                    field    : 'equity_isin', 
                    title    : 'Equity ISIN', 
                    minWidth : 200, 
                    params : {
                        filter : 'input',
                    },
                },{
                    field  : 'actual', 
                    title  : 'Reuters ON', 
                    width  : 120, 
                    align  : 'center',
                    params : {
                        filter : 'boolean',
                        editor : 'checkbox', 
                    },
                },
            ],
            filters: {
                isin        : { value: undefined },
                equity_isin : { value: undefined },
                actual      : { value: undefined, strict: true },
            }
        }
    },

    computed: {
    	...mapState({
    		user: state => state.app.user,
    	}),

    	rights(){
    		return {
				view : (this.user && this.user.rights) ? this.user.rights['get-isin']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-isin']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-isin']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-isin']: false,
    		}
    	},
    },
}
</script>
